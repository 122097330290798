import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HomePageTemplate from '../components/HomePageTemplate'
import Layout from '../components/Layout'

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  const [donationTotal, setDonationTotal] = useState(0)

  // fetch the latest donation total from the API
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.fetch === 'function') {
      window.fetch('https://api.ppe4all.net/donations/', {
        method: 'get',
      })
        .then(response => response.json())
        .then(jsonData => {
          setDonationTotal(jsonData.donationTotal)
        })
    }
  }, [])

  return (
    <Layout>
      <HomePageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        card_title={frontmatter.card_title}
        card_button_title={frontmatter.card_button_title}
        card_route={frontmatter.card_route}
        heading={frontmatter.heading}
        description={frontmatter.description}
        offerings={frontmatter.offerings}
        donationTotal={donationTotal}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        card_title
        card_button_title
        card_route
        heading
        description
        offerings {
          blurbs {
            image
            text
            title
            linkTo
          }
        }
      }
    }
  }
`
