import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Offerings from '../Offerings'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import CarouselSponsors from '@brainhubeu/react-carousel'
import './index.css'
import MediumPosts from '../MediumPosts'
import StarrySky from '../StarrySky'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Anime, {anime} from 'react-anime';
import TextLoop from "react-text-loop";
import { CountUp } from 'countup.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


function FaceShieldTotals ({ items }) {
  const totals = {
    faceShields: 0,
  }

  if (items == null) {
    return (
      <p className='title has-text-white'>
        0
      </p>
    )
  }

  // iterate through the items list and sum each item type
  for (let i = 0; i < items.length; i++) {
    const item = items[i].data

    const faceShields = parseInt(item.Total_Donations)

    if (!Number.isNaN(faceShields)) {
      totals.faceShields += faceShields
    }
  }

  // format totals with commas for display
  for (const itemType in totals) {
    const total = totals[itemType]
    if (!Number.isNaN(total)) {
      totals[itemType] = Number(total).toLocaleString()
    }
  }

  return (
    <p className='title has-text-white'>
      {totals.faceShields}
    </p>
  )
}

function DonationTotal (total, { items }) {

  const cumulative = {
    totalValue: 0,
  }

  cumulative.totalValue += total.total;

  for (let i = 0; i < total.items.length; i++) {
    const item = total.items[i].data
    console.log(item);

    const amt = parseInt(item.Value)
    cumulative.totalValue += amt;
  }

  // round down to the nearest dollar and add commas
  const dollars = Number(Math.floor(cumulative.totalValue)).toLocaleString('en')

  // get the decimal portion, fix it to two decimals, and truncate the '0.'
  const cents = (cumulative.totalValue % 1).toFixed(2).substring(2)

  return (
    <p className='title has-text-white'>$ {dollars}<span className='is-size-5'>.{cents}</span></p>
  )
}

if (typeof window !== 'undefined') window.onscroll = function () { toggleVideo() }

function toggleVideo () {
  const homeVideoEl = document.getElementById('homeVideo')
  if (homeVideoEl === null || homeVideoEl === undefined) {
    return
  }

  if (document.body.scrollTop > 1700 || document.documentElement.scrollTop > 1700) {
    homeVideoEl.className = 'test'
  } else {
    homeVideoEl.className = 'parallaxVideo'
  }
}

const HomePageTemplate = ({
  heading,
  offerings,
  meta_title,
  meta_description,
  donationTotal,
}) => {

  const data = typeof window !== 'undefined' && window.location.pathname.indexOf('admin') > 0 ? null : 
  useStaticQuery(graphql`
    query HomeQuery {
      DistUpdates: allAirtable(
        filter: { table: { eq: "Distribution Updates" } }
      ) {
        nodes {
          data {
            Total_Donations
          }
          recordId
        }
      }
      inKindDonations: allAirtable(
        filter: { table: { eq: "PPE Inventory" } }
      ) {
        nodes {
          data {
            Value
          }
          recordId
        }
      }
      volunteer: allAirtable(
        filter: { table: { eq: "In-Person Volunteers" } }
      ) {
        nodes {
          data {
            Name
          }
          recordId
        }
      }
    }
    `)

    if(typeof window !== 'undefined' || typeof document !== 'undefined'){
      if (window.location.pathname.indexOf('admin') > 0) {
        AOS.init({
          disable: false,
        });
      } else AOS.init({disable: false});
    }
  return (
    <div>

      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
        <link rel='image_src' href='https://ppe4nyc.org/icons/icon-512x512.png' />
      </Helmet>
      <div className='Hero'>
        <StarrySky />
        <img src={require('../../assets/img/hero-glow.svg')} class='position-absolute home-hero-glow' />
        <div className='position absolute'>
          <div className='mobile-break'>
            <br />
            <br />
            <br />
          </div>
          {/* <a href='/donate'>
            <div className='bannerlink'>
              <span>With a 1,500% increase in requests, PPE4ALL continues to support communities in need. Help us continue our life-saving work.</span>
            </div>
          </a> */}
        </div>
          <div className='HeroGroup'>
            
            <div className='columns is-vcentered'>
              <div className='column is-third is-offset-1 is-vcentered'>
                <h1 className='title'>
                  We provide PPE to
                  <TextLoop>
                      <span>First Responders.</span>
                      <span>Homeless Shelters.</span>
                      <span>Peaceful Protestors.</span>
                      <span>Academic Institutions.</span>
                      <span>Healthcare professionals.</span>
                      <span>Hospital staff.</span>
                      <span>Governments.</span>
                      <span>Nursing homes.</span>
                      <span>Agriculture Workers.</span>
                      <span>Disability Services.</span>
                      <span>Individuals in need.</span>
                      <span>Indigenous Populations.</span>
                      <span>Small Businesses.</span>
                      <span>Transportation Services.</span>
                  </TextLoop>{" "}
                </h1>
                <h3>{heading}</h3>
                <hr className="solid"/>

                <div class="level-left mt-5">
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">Donations Received</p>
                      <p class="heading">(In-kind + Cash)</p>
                      <DonationTotal total={donationTotal} items={data == null ? null : data.inKindDonations.nodes}/>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">PPE Delivered</p>
                      <FaceShieldTotals items={data == null ? null : data.DistUpdates.nodes} />
                    </div> 
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">Donations Sent</p>
                      <p class="title has-text-white">{data == null ? null : data.DistUpdates.nodes.length}</p>
                    </div>
                  </div>
                  <div class="level-item has-text-centered">
                    <div>
                      <p class="heading">Volunteers</p>
                      <p class="title has-text-white">{data == null ? null : data.volunteer.nodes.length}</p>
                    </div>
                  </div>
                </div>
                <div className='level-left mt-4'>
                  <div className='level-item is-half'>
                    <div className = "call-action-hero center">
                      <a className = "navebar-item" id = "donatebutton" href = "/stats/">
                        <button> Read about our impact 
                          <FontAwesomeIcon className="ml-3" size="sm" icon={faArrowRight} />
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className='level-item is-half'>
                    <div className = "call-action-hero center">
                      <a className = "navebar-item" id = "donatebutton" href = "/request">
                        <button> Request PPE 
                          <FontAwesomeIcon className="ml-3" size="sm" icon={faArrowRight} />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='column is-half is-vcentered planePadding is-hidden-mobile'>
                <div className="planeContainer">
                <Anime easing="easeInOutSine" delay={1000} duration={3000} translateX={"100%"} opacity={(el, index) => index}>
                    <img className="plane" loading='lazy' src={require('../../assets/img/plane.png')}/>
                </Anime>
                </div>
              
                <div class="columns drops">
                  <div class="column">
                      <Anime easing="easeInOutSine" duration={3000} translateY={"25vh"} rotate={45} delay={1000} opacity={(el, index) => index}>
                        <img className="boxImg" loading='lazy' src={require('../../assets/img/box4.png')}/>
                      </Anime>
                    </div>
                    <div class="column">
                      <Anime easing="easeInOutSine" duration={3200} translateY={"25vh"} rotate={-45} delay={1050} opacity={(el, index) => index}>
                        <img className="boxImg" loading='lazy' src={require('../../assets/img/box1.png')}/>
                      </Anime>
                    </div>
                    <div class="column">
                      <Anime easing="easeInOutSine" duration={2800} translateY={"25vh"} rotate={45} delay={1150} opacity={(el, index) => index}>
                        <img className="boxImg" loading='lazy' src={require('../../assets/img/box2.png')}/>
                      </Anime>
                    </div>
                    <div class="column">
                      <Anime easing="easeInOutSine" duration={2500} translateY={"25vh"} rotate={-45} delay={1250} opacity={(el, index) => index}>
                        <img className="boxImg" loading='lazy' src={require('../../assets/img/box3.png')}/>
                      </Anime>
                    </div>
                    <div class="column">
                      <Anime easing="easeInOutSine" duration={3000} translateY={"25vh"} rotate={45} delay={1350} opacity={(el, index) => index}>
                        <img className="boxImg" loading='lazy' src={require('../../assets/img/box2.png')}/>
                      </Anime>
                    </div>
                    <div class="column">
                      <Anime easing="easeInOutSine" duration={3200} translateY={"25vh"} rotate={45} delay={1450} opacity={(el, index) => index}>
                        <img className="boxImg" loading='lazy' src={require('../../assets/img/box4.png')}/>
                      </Anime>
                    </div>
                  </div>
                <img className="city" loading='lazy' src={require('../../assets/img/city.png')}/>
              </div>
            </div>
          </div>

          <div className='hero-foot center'>
            <svg width="100%"id="bg-svg" viewBox="0 0 1440 250"><defs><linearGradient id="gradient"><stop offset="5%" stop-color="#71db77ff"></stop><stop offset="95%" stop-color="#84b7f6ff"></stop></linearGradient></defs><path transform="translate(0,-100)" d="M 0,400 C 0,400 0,200 0,200 C 254,235 508,270 748,270 C 988,270 1214,235 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient)"></path></svg>
          </div>
          <div className = "call-action-donate center">
                  <a className = "navebar-item" id = "donatebutton" href = "/donate/">
                    <button>Support our cause 
                      <FontAwesomeIcon className="ml-3" size="sm" icon={faArrowRight} />
                    </button>
                  </a>
                </div>
      </div>
      <div className='callToAction center'>
          <div className='container'>
            <div className='section'>
              <div className='columns'>
                <div className='column is-10 is-offset-1'>
                  <div className='content'>
                    <Offerings gridItems={offerings.blurbs} className='column is-4 is-centered' />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <svg width="100%" id="bg-svg-bottom" viewBox="0 0 1440 180"><defs><linearGradient id="gradient2"><stop offset="5%" stop-color="#84b7f6ff"></stop><stop offset="95%" stop-color="#71db77ff"></stop></linearGradient></defs><path transform="translate(0,-150)" d="M 0,400 C 0,400 0,200 0,200 C 254,235 508,270 748,270 C 988,270 1214,235 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient2)"></path></svg>
      <section className='section3'>
          <div className='container'>
            <div className='section'>
              <div className='columns'>
                <div className='column is-10 is-offset-1'>
                  <div className='content'>
                    <h5 className='styledHeader padtop is-centered'>Our PPE protects groups large and small.</h5>
                    <img className='collage-img' loading='lazy' src={require('../../assets/img/large_2022_collage.png')} />
                    <p className='is-centered'> Our recipients are frontline workers, hospitals, essential staff, and more. </p>
                    <CarouselSponsors
                      draggable={false}
                      slidesPerPage={4}
                      infinite autoPlay={2000}
                      animationSpeed={1000}
                      breakpoints={{
                        1000: { // these props will be applied when screen width is less than 1000px
                          slidesPerPage: 2,
                          clickToChange: false,
                          centered: false,
                        },
                        500: {
                          slidesPerPage: 1,
                          slidesPerScroll: 1,
                          clickToChange: false,
                          centered: false,
                        },
                      }}>
                      <a href='https://www.housingworks.org/'><img loading='lazy' className='bw' alt='Housing Works logo' src={require('../../assets/img/HousingWorks.png')} height='100px' width='100px' /></a>
                      <a href='https://www.riseboro.org/rb/health/home_care/?source=homecare'><img loading='lazy' alt='Riseboro logo' src={require('../../assets/img/riseboro.jpg')} height='100px' width='100px' /></a>
                      <a href='https://www.ynhh.org/'><img loading='lazy' alt='Yale Health logo' className='bw' src={require('../../assets/img/yalenhhosp.png')} height='100px' width='100px' /></a>
                      <a href='https://www1.nyc.gov/site/nypd/index.page'><img loading='lazy' alt='NYPD logo' src={require('../../assets/img/nypd.png')} height='100px' width='100px' /></a>
                      <img loading='lazy' alt='Columbia logo' src={require('../../assets/img/Columbia.svg')} height='600px' />
                      <a href='https://www1.nyc.gov/assets/dsny/site/home'><img loading='lazy' alt='NYC Department of Sanitation Logo' src={require('../../assets/img/DoS.png')} height='100px' width='100px' /> </a>
                      <a href='https://lenoxhill.northwell.edu/'><img loading='lazy' alt='Lenox Hill logo' src={require('../../assets/img/Lenox.png')} height='100px' width='100px' /> </a>
                      <a href='https://www.mountsinai.org/'><img loading='lazy' alt='Mount Sinai logo' src={require('../../assets/img/MountSinai.png')} height='100px' width='120px' /> </a>
                      <a href='https://hudsonriverhousing.org/'><img loading='lazy' alt='Hudson River Housing logo' className='bw' src={require('../../assets/img/HudsonRiver.png')} height='150px' width='150px' /></a>
                      <a href='https://new.mta.info/'><img loading='lazy' alt='MTA logo' src={require('../../assets/img/MTA.png')} height='100px' width='100px' /> </a>
                      <a href='https://www.montefiore.org/'><img loading='lazy' alt='Montefiore Logo' src={require('../../assets/img/Montefiore.png')} height='100px' width='200px' /> </a>
                      <a href='https://www.bronxcare.org/'><img loading='lazy' alt='BronxCare logo' src={require('../../assets/img/BronxCare.png')} height='100px' width='350px' /></a>
                    </CarouselSponsors>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section3'>
        <svg width="100%" id="bg-svg-cards" viewBox="0 0 1440 350" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient3"><stop offset="5%" stop-color="#002bdc88"></stop><stop offset="95%" stop-color="#32ded488"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,133 0,133 C 158.2666666666667,109.80000000000001 316.5333333333334,86.60000000000001 491,98 C 665.4666666666666,109.39999999999999 856.1333333333334,155.4 1017,167 C 1177.8666666666666,178.6 1308.9333333333334,155.8 1440,133 C 1440,133 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient3)" class="transition-all duration-300 ease-in-out delay-150"></path><defs><linearGradient id="gradient4"><stop offset="5%" stop-color="#002bdcff"></stop><stop offset="95%" stop-color="#32ded4ff"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,266 0,266 C 168.66666666666669,280.5333333333333 337.33333333333337,295.06666666666666 475,286 C 612.6666666666666,276.93333333333334 719.3333333333333,244.26666666666668 875,237 C 1030.6666666666667,229.73333333333332 1235.3333333333335,247.86666666666667 1440,266 C 1440,266 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient4)" class="transition-all duration-300 ease-in-out delay-150"></path></svg>
          <div className='containerDiv'> 
          <div className='container cards'>
            <h5 className='styledHeader padbottom is-centered padtop'>Our stories.</h5>
            <MediumPosts> </MediumPosts>
          </div>
          </div>
          
        </section>
        <svg height="100%" width="100%" id="bg-svg-bottom" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient5"><stop offset="5%" stop-color="#32ded488"></stop><stop offset="95%" stop-color="#002bdc88"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,133 0,133 C 103.03571428571428,114.07142857142857 206.07142857142856,95.14285714285714 318,103 C 429.92857142857144,110.85714285714286 550.7499999999999,145.5 688,150 C 825.2500000000001,154.5 978.9285714285713,128.85714285714286 1107,121 C 1235.0714285714287,113.14285714285714 1337.5357142857142,123.07142857142857 1440,133 C 1440,133 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient5)" class="transition-all duration-300 ease-in-out delay-150"></path><defs><linearGradient id="gradient6"><stop offset="5%" stop-color="#32ded4ff"></stop><stop offset="95%" stop-color="#002bdcff"></stop></linearGradient></defs><path d="M 0,400 C 0,400 0,266 0,266 C 150,246.60714285714286 300,227.21428571428572 419,218 C 538,208.78571428571428 626,209.75000000000003 719,231 C 812,252.24999999999997 910,293.7857142857143 1031,303 C 1152,312.2142857142857 1296,289.1071428571429 1440,266 C 1440,266 1440,400 1440,400 Z" stroke="none" stroke-width="0" fill="url(#gradient6)" class="transition-all duration-300 ease-in-out delay-150"></path></svg>
        <section className='section3'>
          <div className='container'>
            <h5 className='styledHeader padbottom is-centered'>Our efforts.</h5>
          </div>
        </section>
        <section className='section2'>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='0'>
            <div className='gridImage'>
              <img src={require('../../assets/img/photo11.jpg')} loading='lazy' alt='3D printed headbands made of PLA filament' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>The 3D printed headbands we use to assemble face shields are made of PLA filament.</div>
            </div>
          </div>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='100'>
            <div className='gridImage'>
              <img src={require('../../assets/img/photo1.jpg')} loading='lazy' alt='Volunteers from the Hudson Valley assembling face shields at Red Maple Vineyard' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>Each week, volunteers from the Hudson Valley have assembled hundreds of face shields at Red Maple Vineyard, which offered its beautiful space for our use.</div>
            </div>
          </div>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='200'>
            <div className='gridImage'>
              <img src={require('../../../static/img/photo2.jpg')} loading='lazy' alt='Our founder and CEO Krishna with bags of PPE' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>Our CEO and founder, Krishna, with bags of PPE before its distribution.</div>
            </div>
          </div>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='300'>
            <div className='gridImage'>
              <img src={require('../../assets/img/photo3.jpg')} loading='lazy' alt='Volunteers assembling face shields from raw materials such as rolls of PET sheeting' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>Volunteers assemble face shields from raw materials such as rolls of PET sheeting, which are cut into pieces to form the shield.</div>
            </div>
          </div>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='50'>
            <div className='gridImage'>
              <img src={require('../../../static/img/photo5.jpg')} loading='lazy' alt='Contribution from MAPS, an organization with 3D printing capabilities located in Portland, Oregon' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>People from across the country with 3D printing capabilities produce and donate the pieces used to make many of our face shields. Here are contributions from MAPS, located in Portland, Oregon.</div>
            </div>
          </div>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='150'>
            <div className='gridImage'>
              <img src={require('../../assets/img/photo4.jpg')} loading='lazy' alt='PET sheeting is hole punched to fit onto 3D printed headbands' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>The PET sheeting is hole-punched to fit onto 3D printed headbands.</div>
            </div>
          </div>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='250'>
            <div className='gridImage'>
              <img src={require('../../assets/img/photo7.jpg')} loading='lazy' alt='Volunteers carefully putting together face shield parts' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>Our volunteers carefully put together the face shield parts so the PPE is ready to use as soon as it reaches people.</div>
            </div>
          </div>
          <div className='gridItem' data-aos='zoom-in' data-aos-duration='1000' data-aos-delay='350'>
            <div className='gridImage'>
              <img src={require('../../assets/img/photo8.jpg')} loading='lazy' alt='Health care worker, one of many whom we have delivered face shields to' />
            </div>
            <div className='overlay'>
              <div className='overlayText'>We have delivered thousands of face shields to transportation workers, sanitation workers, aid organizations, small business, and health care workers like those pictured.</div>
            </div>
          </div>
        </section>
    </div>
  )
}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  card_title: PropTypes.string,
  card_button_title: PropTypes.string,
  card_route: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  donationTotal: PropTypes.number,
}

export default HomePageTemplate
