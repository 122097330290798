import React from 'react'

class ExpandableText extends React.Component{
    constructor(props){
	super(props);
	this.state = {
	    expanded: false
	}
    }

    toggleExpansion = () => {
	const {expanded} = this.state;
	this.setState({
	    expanded: !expanded
	});
    }
    
    render(){
	const {
	    text,
	    truncateLimit
	} = this.props;

	if(text.length < truncateLimit){
	    return <p className="offeringsP" id="notExpanded">{text}</p>;
	}
	
	const {expanded} = this.state;

	const readmore = <a onClick={this.toggleExpansion}> {expanded ? 'read less' : 'read more'}</a>;
	if( expanded) {
	    return <p className="offeringsP">{text}{readmore}</p>;
	}else{
	    return <p className="offeringsP">{text.substring(0, truncateLimit)} {readmore}</p>;
	}
    }
}

ExpandableText.defaultProps = {
    truncateLimit: 250,
}
	    
export default ExpandableText;
