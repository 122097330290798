import React from 'react'
import PropTypes from 'prop-types'
import ExpandableText from '../ExpandableText'
import { Link } from 'gatsby'
import './index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function animationType (sectionName) {
  if (sectionName === 'Make' || sectionName === 'Volunteer') {
    return 'flip-left'
  }
}

function animationDelay (sectionName) {
  if (sectionName === 'Make' || sectionName === 'Volunteer') {
    return '700'
  }
}

const Offerings = ({ gridItems, className, sectionName }) => {
  if(typeof window !== 'undefined' || typeof document !== 'undefined'){
    if (window.location.pathname.indexOf('admin') > 0) {
      AOS.init({
        disable: false,
      });
    } else AOS.init({disable: false});
  }
  
  return (
    <div className='columns is-multiline is-centered'>
      {gridItems.map(item => (
        <div key={item.image} className={className} style={{ borderRadius: '5px' }}>
          <section className='section' data-aos={animationType(sectionName)} data-aos-duration='1500' data-aos-delay={animationDelay(sectionName)}>
            <p className='has-text-centered'>
              <img alt={item.image} src={item.image} />
            </p>
            <h3 className='offeringsText is-centered'>{item.title}</h3>
            <ExpandableText text={item.text} />
            {sectionName === 'Volunteer' &&
              <Link to={item.linkTo}><button>Learn more</button></Link>
            }
            {sectionName !== 'Make' && sectionName !== 'Volunteer' &&
              <Link to={item.linkTo}><button>{item.linkTo}<FontAwesomeIcon className="ml-2" size="sm" icon={faArrowRight} />
              </button></Link>
            }
          </section>
        </div>
      ))}
    </div>
)}

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
      linkTo: PropTypes.string,
    }),
  ),
}

export default Offerings
